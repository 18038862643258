import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiDatepicker } from '../src';
import { DatePickerExample } from './example';
import { UiBadge } from '@uireact/badge';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <UiBadge category="warning" mdxType="UiBadge">❗️ Beta</UiBadge>
    <h1 {...{
      "id": "uidatepicker"
    }}>{`UiDatepicker`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/COMPONENT-NAME/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`A datepicker that lets select a date`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/flex @uireact/button @uireact/grid @uireact/menu @uireact/text @uireact/datepicker`}</p>
    </blockquote>
    <h2 {...{
      "id": "datepicker"
    }}>{`Datepicker`}</h2>
    <Playground __position={2} __code={'<DatePickerExample />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiDatepicker,
      DatePickerExample,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePickerExample mdxType="DatePickerExample" />
    </Playground>
    <br />
    <p>{`This example uses these 2 props to change the behavior of the date picker in small screens:`}</p>
    <ol>
  <li>`useDialogOnSmall` - When screen is small the datepicker will be opened in a dialog.</li>
      {' '}
      <li>
  As there the visibility is now in a state, we can show and hide the datepicker to our convenience.
  <ul>
    <li> The Datepicker will be closed if user presses ESC key. </li>
    <li> The Datepicker will be closed if the user clicks outside the datepicker. </li>
    <li> Inside the Dialog if the user clicks on the `Close` button then it will trigger the `onClose` CB. </li>
  </ul>
      </li>
  <li> 3. The `closeLabel` prop is passing the label used inside the dialog in the close button. </li>
    </ol>
    <br />
    <p>{`The callback `}<inlineCode parentName="p">{`onClose`}</inlineCode>{` is optional as there might be a use case where you would like to close the datepicker when the `}<inlineCode parentName="p">{`onSelectDate`}</inlineCode>{` callback
is triggered, depends on the use case you might rely on the selection or on the close callback. In this example we are using the
onClose CB to close the datepicker.`}</p>
    <br />
    <p>{`DatePickerExample:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export const DatePickerExample: React.FC = () => {
  const [dateSelected, setDateSelected] = useState<Date | undefined>();
  const [datepickerVisible, setDatepickerVisible] = useState<boolean>(false);

  const onSelectDate = useCallback(
    (date) => {
      setDateSelected(date);
    },
    [setDateSelected]
  );

  const onOpenClick = useCallback(() => {
    setDatepickerVisible(true);
  }, [setDatepickerVisible]);

  const onClose = useCallback(() => {
    setDatepickerVisible(false);
  }, [setDatepickerVisible]);

  return (
    <div>
      <UiButton onClick={onOpenClick} category="tertiary">
        <UiSpacing padding={buttonSpacing}>Open date picker</UiSpacing>
      </UiButton>
      <UiDatepicker
        date={today}
        onSelectDate={onSelectDate}
        highlightToday
        isOpen={datepickerVisible}
        onClose={onClose}
        useDialogOnSmall
        showNextMonth
        closeLabel="Accept"
      />
      {dateSelected && (
        <UiSpacing padding={textSpacing}>
          <UiText>
            Date selected: {\`\${dateSelected.getFullYear()}/\${dateSelected.getMonth() + 1}/\${dateSelected.getDate()}\`}
          </UiText>
        </UiSpacing>
      )}
    </div>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiDatepicker} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      